import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import { getData } from "../../services/service";
import Table from "./table/Table";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";

const Portfolio = () => {
  const [data, setData] = useState({
    cards: null,
    lines: null,
    portfolioTitle: null,
    portfolioLabels: null,
    searchInputText: null,
    backgroundImage: null,
    pdftext: null,
    cssProps: null,
  });

  useEffect(() => {
    document.title = "Portfolio Page";

    const fetchData = async () => {
      try {
        const dataKeys = [
          "Properties?fields=id,title,game_icon,launchUrl,order_by,show_hide_game,show_hide_portfolio_game,background_image,New_port_m2m.*.*.*.*",
          "lines",
          "Portfolio_page_title",
          "Portfolio_data",
          "Portfolio_search_input_text",
          "Portfolio_title_background_image",
          "Portfolio_pdf_button",
          "css_position",
        ];

        const fetchedData = {};

        for (const key of dataKeys) {
          const response = await getData(key);
          fetchedData[key] = response.data.data;
        }

        setData((prevData) => ({
          ...prevData,
          cards: fetchedData[dataKeys[0]],
          lines: fetchedData[dataKeys[1]],
          portfolioTitle: fetchedData[dataKeys[2]],
          portfolioLabels: fetchedData[dataKeys[3]],
          searchInputText: fetchedData[dataKeys[4]],
          backgroundImage: fetchedData[dataKeys[5]],
          pdftext: fetchedData[dataKeys[6]],
          cssProps: fetchedData[dataKeys[7]],
        }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const {
    cards,
    lines,
    portfolioTitle,
    portfolioLabels,
    searchInputText,
    backgroundImage,
    pdftext,
    cssProps,
  } = data;

  const isDataLoaded =
    cards !== null &&
    lines !== null &&
    portfolioTitle !== null &&
    portfolioLabels !== null &&
    searchInputText !== null &&
    backgroundImage !== null &&
    pdftext !== null &&
    cssProps !== null;

  return (
    <>
      {isDataLoaded ? (
        <>
          <Table
            backgroundImage={backgroundImage}
            portfolioTitle={portfolioTitle}
            cards={cards}
            lines={lines}
            data={data}
            css={cssProps}
            portfolioLabels={portfolioLabels}
            searchInputText={searchInputText}
            pdftext={pdftext}
          />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Portfolio;
