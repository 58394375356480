import "./Labels.css";

const Labels = ({
  toggleAllLabels,
  isAllLabelsOpen,
  allLabels,
  clickedLabel,
  scrollToLabel,
}) => {
  return (
    <div className="allLabels-main-div">
      <div className="arrow-button-placeholder">
        <div className="arrow-icon">
          scroll all labels{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path d="M12 2c3.309 0 6 2.691 6 6v8c0 3.309-2.691 6-6 6s-6-2.691-6-6v-8c0-3.309 2.691-6 6-6zm0-2c-4.418 0-8 3.582-8 8v8c0 4.418 3.582 8 8 8s8-3.582 8-8v-8c0-4.418-3.582-8-8-8zm0 9c-.829 0-1.5-.672-1.5-1.5s.671-1.5 1.5-1.5 1.5.672 1.5 1.5-.671 1.5-1.5 1.5z" />
          </svg>
        </div>
        <button
          style={{
            backgroundColor: !isAllLabelsOpen ? "#ff0037" : "",
            color: !isAllLabelsOpen ? "#f7fff7" : "",
          }}
          className="collapsible-button"
          onClick={toggleAllLabels}
        >
          {isAllLabelsOpen && "Filter"}
          {!isAllLabelsOpen && "Close"}
        </button>
      </div>
      <div className={`all-labels ${isAllLabelsOpen ? "close" : "opens"}`}>
        {allLabels.map((label) => (
          <span
            className={`label ${clickedLabel === label ? "selected" : ""}`}
            key={label}
            onClick={() => scrollToLabel(label)}
          >
            {label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Labels;
