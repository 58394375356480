import "./Footer.css";
import React, { useState, useEffect } from "react";
import { getData } from "../../services/service";
import { DOMAIN } from "../../services/domain";
import { Link } from "react-router-dom";

const Footer = () => {
  const [info, setInfo] = useState([]);
  // const [cssStyle, setCssStyle] = useState([]);

  // useEffect(() => {
  //   getData("Footer_css").then((res) => {
  //     setCssStyle(res.data.data);
  //   });
  // }, []);

  useEffect(() => {
    getData("Footer").then((res) => {
      setInfo(res.data.data);
    });
  }, []);

  return (
    info && (
      <>
        {/* <style dangerouslySetInnerHTML={{ __html: cssStyle[0]?.css }} /> */}
        <div id="footer">
          <div className="container">
            {info.map((item) => {
              return (
                <div key={item.id} className="row-footer">
                  <div className="col-xs-12 col-sm-6 footer-link d-flex mr-2">
                    <img
                      id="footerLogo"
                      src={`${DOMAIN}/assets/${item.logo}`}
                      alt="footer logo"
                    />
                    <div className="footer-text-div">
                      <h5 className="footer-h5">{item.company_name}</h5>
                      <p className="footer-paragraph-privacy">{item.address}</p>
                      <Link to="/policy">{item.privacy_link}</Link>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-3 col-md-offset-3 footer-right">
                    <img
                      id="footerGamblingImg"
                      src={`${DOMAIN}/assets/${item.commisionLogo}`}
                      alt="Gambling Commission Logo"
                    />
                    <p className="footer-commission-paragraph">
                      {item.description} &nbsp;
                      <a
                        id="responsible-gaming"
                        href={item.gambling_commision_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.gambling_commision_number}
                      </a>
                      .
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
};

export default Footer;
