import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useLinesConditions } from "../useFunctions/useLinesConditions";
import { filteredGames } from "../filteredGames/filteredGames";
import PortfolioTitle from "../PortfolioTitle";
import Arrows from "../arrows/Arrows";
import PdfButton from "../pdfButton/PdfButton";
import Labels from "../labels/Labels";

const SearchInput = React.lazy(() => import("../searchInput/SearchInput"));
const PortfolioItem = React.lazy(() => import("../PortfolioItem"));
const NoMatchesFound = React.lazy(() =>
  import("../noMatchesFound/NoMatchesFound")
);

const Table = ({
  cards,
  lines,
  searchInputText,
  data,
  pdftext,
  portfolioLabels,
  css,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { upcomingLinesRow, latestReleasedRow, assetsRow } =
    useLinesConditions(lines);
  const containerRef = useRef(null);
  const tableRef = useRef(null);
  const [selectedLabels] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const [clickedLabel, setClickedLabel] = useState(null);
  const [isAllLabelsOpen, setIsAllLabelsOpen] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const toggleAllLabels = () => {
    setIsAllLabelsOpen(!isAllLabelsOpen);
  };

  const isLabelSelected = useCallback(
    (label) => {
      return selectedLabels.includes(label) && clickedLabel !== label;
    },
    [selectedLabels, clickedLabel]
  );

  useEffect(() => {
    // Extract all labels from cards
    const labels = cards.flatMap((card) =>
      card.New_port_m2m.map(
        (item) => item?.Portfolio_id?.Port_item?.column_label
      )
    );

    // Remove duplicates and sort labels alphabetically
    const uniqueLabels = [...new Set(labels)];

    // Update the allLabels state
    setAllLabels(uniqueLabels);
  }, [cards]);

  const filteredCards = useMemo(() => {
    return cards?.filter((card) => {
      return card?.New_port_m2m?.some((item) =>
        isLabelSelected(item?.Portfolio_id?.Port_item?.column_label)
      );
    });
  }, [cards, isLabelSelected]);

  const filteredCardsSearch = useMemo(
    () => filteredGames(cards, searchTerm),
    [cards, searchTerm]
  );

  const handleSearchTermChange = useCallback((value) => {
    setSearchTerm(value);
    setIsSearching(true);
  }, []);

  const scrollToLabel = useCallback(
    (label) => {
      if (tableRef.current && containerRef.current) {
        const tableHead = tableRef.current.querySelector("thead");
        if (tableHead) {
          const matchedCell = Array.from(tableHead.querySelectorAll("th")).find(
            (cell) => cell.innerText.toLowerCase() === label.toLowerCase()
          );

          if (matchedCell) {
            if (clickedLabel === label) {
              setClickedLabel(null); // Reset the clicked label
              containerRef.current.style.transition =
                "scrollLeft 0.5s ease-in-out";
              containerRef.current.style.transition =
                "scrollTop 0.5s ease-in-out";
              containerRef.current.scrollLeft = 0; // Reset scrollLeft to 0
              containerRef.current.scrollTop = 0; // Reset scrollTop to 0
            } else {
              setClickedLabel(label); // Update the clicked label
              const container = containerRef.current;
              const scrollOffset =
                matchedCell.offsetLeft - container.offsetLeft;

              container.style.scrollBehavior = "smooth"; // Add smooth transition
              container.scrollLeft = scrollOffset;

              if (window.innerWidth < 768) {
                const cellWidth = matchedCell.offsetWidth;

                container.style.scrollBehavior = "smooth"; // Add smooth transition
                container.scrollLeft -= cellWidth;
              }
            }
          }
        }
      }
    },
    [clickedLabel, setClickedLabel, tableRef, containerRef]
  );

  useEffect(() => {
    scrollToLabel(searchTerm);
  }, [searchTerm, scrollToLabel]);

  useEffect(() => {
    if (isSearching) {
      if (searchTerm.length >= 3 && containerRef.current && tableRef.current) {
        const matchedCell = Array.from(
          tableRef.current.querySelectorAll("td")
        ).find((cell) =>
          cell.innerText.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (matchedCell) {
          const container = containerRef.current;
          const scrollOffset = matchedCell.offsetLeft - container.offsetLeft;

          container.scrollLeft = scrollOffset;

          if (window.innerWidth < 768) {
            const cellWidth = matchedCell.offsetWidth;

            container.scrollLeft -= cellWidth;
          }

          const tableRows = Array.from(tableRef.current.querySelectorAll("tr"));
          const rowIndex = tableRows.findIndex((row) =>
            row.contains(matchedCell)
          );
          if (rowIndex >= 0) {
            const cellHeight = matchedCell.offsetHeight;
            const scrollOffsetY = rowIndex * cellHeight;

            container.style.transition = "scrollTop 0.5s ease-in-out";
            container.scrollTop = scrollOffsetY - cellHeight;
          }
        }
      } else if (containerRef.current) {
        containerRef.current.style.transition = "scrollLeft 0.5s ease-in-out";
        containerRef.current.style.transition = "scrollTop 0.5s ease-in-out";
        containerRef.current.scrollLeft = 0;
        containerRef.current.scrollTop = 0;
      }
    }
  }, [searchTerm, isSearching, scrollToLabel]);
  return (
    <div className="portfolio-div-main">
      <PortfolioTitle
        portfolioTitle={data.portfolioTitle}
        backgroundImage={data.backgroundImage}
      />
      <div className="filters">
        <SearchInput
          searchTerm={searchTerm}
          onSearchTermChange={handleSearchTermChange}
          searchInputText={searchInputText}
          setSearchTerm={setSearchTerm}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
        />
      </div>
      {filteredCardsSearch.length > 0 && (
        <Labels
          toggleAllLabels={toggleAllLabels}
          isAllLabelsOpen={isAllLabelsOpen}
          allLabels={allLabels}
          clickedLabel={clickedLabel}
          scrollToLabel={scrollToLabel}
          isFocused={isFocused}
        />
      )}

      {filteredCardsSearch.length > 0 && (
        <Arrows searchInputText={searchInputText} containerRef={containerRef} />
      )}
      {filteredCardsSearch.length > 0 && !filteredCards.length > 0 && (
        <div className="table-wrapper one" ref={containerRef}>
          <table ref={tableRef} id="table-content">
            {filteredCardsSearch.map((item, index) => (
              <PortfolioItem
                css={css}
                key={item.id}
                item={item}
                cards={cards}
                index={index}
                lines={lines}
                portfolioLabels={portfolioLabels}
                upcomingLinesRow={upcomingLinesRow - 1}
                latestReleasedRow={latestReleasedRow - 1}
                assetsRow={assetsRow - 1}
                searchTerm={searchTerm}
                searchInputText={searchInputText}
                clickedLabel={clickedLabel}
              />
            ))}
          </table>
        </div>
      )}
      {!searchTerm && (
        <PdfButton
          tableRef={tableRef}
          filteredCardsSearch={filteredCardsSearch}
          pdftext={pdftext}
        />
      )}

      <div className="no-matches-wrapper">
        <NoMatchesFound
          searchTerm={searchTerm}
          filteredCardsSearch={filteredCardsSearch}
        />
      </div>
    </div>
  );
};

export default Table;
