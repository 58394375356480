import { useState, useEffect, useRef } from "react";
import "./Arrows.css";
import { DOMAIN } from "../../../services/domain";

const Arrows = ({ searchInputText, containerRef }) => {
  const [displayMode, setDisplayMode] = useState("text");
  const [showMessage, setShowMessage] = useState(true);
  const intervalIdRef = useRef(null);
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  const scrollAmount = 300;
  const scrollDuration = 200; // Duration for each step-wise scroll

  const handleMove = (direction) => {
    const container = containerRef.current;
    const start = container.scrollLeft;
    const change = direction === "left" ? -scrollAmount : scrollAmount;
    const increment = 200;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const newPosition = Math.easeInOutQuad(
        currentTime,
        start,
        change,
        scrollDuration
      );
      container.scrollLeft = newPosition;

      if (currentTime < scrollDuration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };

  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const handleStep = (direction) => {
    handleMove(direction);
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      handleMove(direction);
    }, scrollDuration);
  };

  const handleStartContinuous = (direction) => {
    handleMove(direction);
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      handleMove(direction);
    }, 100);
  };

  const handleStop = () => {
    clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    intervalIdRef.current = setInterval(() => {
      setDisplayMode((mode) => (mode === "text" ? "image" : "text"));
    }, 6000);
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 5000);
  }, []);

  return (
    <div className="arrows">
      <span
        className="message left"
        onMouseDown={!isMobileDevice ? () => handleStep("left") : null}
        onMouseUp={!isMobileDevice ? handleStop : null}
        onTouchStart={
          isMobileDevice ? () => handleStartContinuous("left") : null
        }
        onTouchEnd={isMobileDevice ? handleStop : null}
        onTouchCancel={isMobileDevice ? handleStop : null}
      >
        {"<"}
      </span>
      {displayMode === "text" && !showMessage && (
        <h3
          className="arrow-text"
          style={{
            marginBottom: "0",
            color: searchInputText[0]?.arrow_title_color,
          }}
        >
          {searchInputText[0]?.arrow_title}
        </h3>
      )}
      {displayMode === "image" && !showMessage && (
        <img
          className="arrow-image"
          src={`${DOMAIN}/assets/${searchInputText[0]?.image_title}`}
          alt={searchInputText[0]?.arrow_title}
        />
      )}

      <span
        className="message right"
        style={{ paddingRight: showMessage ? "0" : "10px" }}
        onMouseDown={!isMobileDevice ? () => handleStep("right") : null}
        onMouseUp={!isMobileDevice ? handleStop : null}
        onTouchStart={
          isMobileDevice ? () => handleStartContinuous("right") : null
        }
        onTouchEnd={isMobileDevice ? handleStop : null}
        onTouchCancel={isMobileDevice ? handleStop : null}
      >
        {showMessage ? (
          <div className="message">
            <p>Hold to scroll faster</p>
          </div>
        ) : (
          ">"
        )}
      </span>
    </div>
  );
};

export default Arrows;
