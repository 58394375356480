import axios from "axios";
import { DOMAIN } from "./domain";

//All endpoints from Directus CMS
// -----------------------------------------------------
export const getData = (value) => {
  return axios.get(`${DOMAIN}/items/${value}`);
};
export const getDataId = (id, value) => {
  return axios.get(`${DOMAIN}/items/${value}/${id}`);
};
export const getAllUrlEndpoints = (id) => {
  return axios.get(`${DOMAIN}/items/Properties/${id}?fields=launchUrl`);
};
export const getSingleFeatureGame = (id) => {
  return axios.get(`${DOMAIN}/items/Properties/${id}?fields=single_props.*`);
};
export const getSinglePortfolioItem = (id) => {
  return axios.get(
    `${DOMAIN}/items/Properties/${id}?fields=id,title,game_icon,launchUrl,order_by,games_description,show_hide_game,show_hide_portfolio_game,background_image,New_port_m2m.*.*.*.*`
  );
};
export const getSingleFeatureGameProps = (id) => {
  return axios.get(
    `${DOMAIN}/items/Properties/${id}?fields=single_property.*.*`
  );
};
export const getSingleFeatureDataProps = (id) => {
  return axios.get(`${DOMAIN}/items/Properties/${id}?fields=single_data.*.*`);
};
export const getDataStrapi = (value) => {
  return axios.get(`http://localhost:1337/api/${value}`);
};
