import { Route, Routes } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import "./Nav.css";
import Nav from "./Nav";
import Loader from "../loader/Loader";
import Portfolio from "../../pages/portfolio/Portfolio";

const Home = lazy(() => import("../../pages/home/Home"));
const About = lazy(() => import("../../pages/about/About"));
const Contact = lazy(() => import("../../pages/contact/Contact"));
const Games = lazy(() => import("../games/Games"));
const SingleGame = lazy(() => import("../games/SingleGame"));
const GameIframe = lazy(() => import("../gameiframe/GameIframe"));
const NavContents = lazy(() => import("../navigationContents/NavContents"));
const SingleNavContents = lazy(() =>
  import("../navigationContents/SingleNavContents")
);
const PortfolioDetails = lazy(() =>
  import("../../pages/portfolio/PortfolioDetails")
);
const Footer = lazy(() => import("../footer/Footer"));
const Policy = lazy(() => import("../footer/Policy"));
const NotFound = lazy(() => import("../notFoundPage/NotFound"));

const routes = [
  { path: "/", component: Home },
  { path: "/about-us", component: About },
  { path: "/contact", component: Contact },
  { path: "/games", component: Games },
  { path: "/games/:id", component: SingleGame },
  { path: "/navcontents", component: NavContents },
  { path: "/navcontents/:id", component: SingleNavContents },
  { path: "/footer", component: Footer },
  { path: "/policy", component: Policy },
  { path: "/gameiframe", component: GameIframe },
  { path: "/portfolio/:id", component: PortfolioDetails },
  { path: "/portfolio", component: Portfolio },
  { path: "*", component: NotFound },
];

const Navbar = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Nav />
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </Suspense>
    </>
  );
};

export default Navbar;
