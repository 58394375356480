import html2pdf from "html2pdf.js";
import { useState, useRef } from "react";

const PdfButton = ({ tableRef, filteredCardsSearch, pdftext }) => {
  const [isConverting, setIsConverting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cachedPDF, setCachedPDF] = useState(null);
  const progressIntervalRef = useRef(null);

  const convertToPDF = async () => {
    const table = tableRef.current;
    const pageWidth = table.clientWidth;
    const pageHeight = table.clientHeight;

    setIsConverting(true);

    const pdfOptions = {
      filename: "games.pdf",
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { useCORS: true, scale: 2, dpi: 300 },
      jsPDF: {
        unit: "px",
        format: [pageWidth, pageHeight],
        orientation: "landscape",
      },
      pagebreak: { mode: "avoid-all" },
    };

    if (cachedPDF) {
      // Use cached PDF if available
      cachedPDF.save();
    } else {
      await new Promise((resolve) => {
        setProgress(0);
        progressIntervalRef.current = setInterval(() => {
          setProgress((prevProgress) => {
            const nextProgress = prevProgress + 1;
            if (nextProgress >= 100) {
              clearInterval(progressIntervalRef.current);
              resolve();
            }
            return nextProgress;
          });
        }, 8);
        const pdf = html2pdf().set(pdfOptions).from(table);
        setCachedPDF(pdf); // Cache the PDF
        pdf.save();
      });
    }

    setIsConverting(false);
    setProgress(0);
  };

  const convertToPDFWithProgress = async () => {
    if (!isConverting) {
      await convertToPDF();
    }
  };

  return (
    <>
      {filteredCardsSearch.length > 0 && (
        <div className="pdf-button-div">
          <button
            style={{
              minWidth: "210px",
              width: `${progress}%` || "210px",
              background: `linear-gradient(to right, ${
                progress ? "#101010" : "#ff0037"
              } ${progress}%, transparent ${progress}%)`,
            }}
            className="pdf-button"
            onClick={convertToPDFWithProgress}
            disabled={isConverting}
          >
            {isConverting ? `${progress}%` : pdftext[0]?.Button_text}
          </button>
        </div>
      )}
    </>
  );
};

export default PdfButton;
