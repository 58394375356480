import "./App.css";
import { Helmet } from "react-helmet";
import Navbar from "./components/navigation/Navbar";
// import { useEffect, useState } from "react";
// import { getDataId } from "./services/service";

const App = () => {
  // const [cssColors, setCssColors] = useState([]);

  // useEffect(() => {
  //   getDataId(1, "Colors").then((res) => {
  //     setCssColors(res.data.data);
  //   });
  // }, []);

  return (
    <>
      {/* <style dangerouslySetInnerHTML={{ __html: cssColors?.all_colors }} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gromada Games</title>
        <base href="/" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <Navbar />
    </>
  );
};

export default App;
