import React, { useState, useEffect } from "react";
import { DOMAIN } from "../../services/domain.js";

const PortfolioTitle = ({ portfolioTitle, backgroundImage }) => {
  const [bgImage, setBgImage] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setBgImage(
          `${DOMAIN}/assets/${backgroundImage[0]?.background_image_mobile}`
        );
      } else {
        setBgImage(
          `${DOMAIN}/assets/${backgroundImage[0]?.background_image_desktop}`
        );
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [backgroundImage]);

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImage + ")",
      }}
      className="portfolio-div"
    >
      <h1
        style={{
          backgroundColor:
            portfolioTitle[0]?.shadow === "active"
              ? "rgba(32, 30, 33, 0.5)"
              : "",
        }}
        className="h1-style"
      >
        {portfolioTitle[0]?.title}
      </h1>
    </div>
  );
};

export default PortfolioTitle;
