import { useMemo } from "react";

export const useLinesConditions = (lines) => {
  //Colored rows between games
  const conditions = useMemo(
    () => ({
      set_assets: lines?.set_assets,
      set_latest_released: lines?.set_latest_released,
      set_upcoming_lines: lines?.set_upcoming_lines,
    }),
    [lines]
  );

  const upcomingLinesRow = parseInt(conditions?.set_upcoming_lines);
  const latestReleasedRow = parseInt(conditions?.set_latest_released);
  const assetsRow = parseInt(conditions?.set_assets);

  return { conditions, upcomingLinesRow, latestReleasedRow, assetsRow };
};
