import { useEffect } from "react";

export const scroll = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const scrollAbout = () =>
  window.scrollTo({
    top: 500,
    left: 0,
    behavior: "smooth",
  });

export const ScrollToTopOnMount = () => {
  useEffect(() => {
    scroll();
  }, []);
  return null;
};
