export const filteredGames = (cards, searchTerm) => {
  return searchTerm.length > 2
    ? cards?.filter(
        (card) =>
          card?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          card?.New_port_m2m.some((item) =>
            item?.Portfolio_id?.Port_item_one_content?.Content?.toLowerCase().includes(
              searchTerm.toLowerCase()
            )
          )
      )
    : cards;
};
