import React, { useState, useEffect } from "react";
import "./Nav.css";
import { getData } from "../../services/service";
import { scroll } from "../../services/scrollToTopFunction";
import { Link, NavLink, useLocation } from "react-router-dom";
import { DOMAIN } from "../../services/domain";

const Nav = () => {
  const [toggle, setToggle] = useState(false);
  const [, setIsOpen] = useState(false);
  const [links, setLinks] = useState([]);
  const location = useLocation();
  // const [cssStyle, setCssStyle] = useState([]);

  // useEffect(() => {
  //   getData("Navigation_css").then((res) => {
  //     setCssStyle(res.data.data);
  //   });
  // }, []);

  useEffect(() => {
    setToggle(false);
    getData("Navigation").then((res) => {
      setLinks(res.data.data);
    });
  }, [location]);

  const closeMenu = () => {
    setIsOpen(true);
  };
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 5);
  };

  return (
    <>
      {/* <style dangerouslySetInnerHTML={{ __html: cssStyle[0]?.css }} /> */}
      <nav className="navbar  navbar-fixed-top">
        <div className="container ">
          <div className="navbar-header">
            <Link className="navbar-brand" to="/" title="Gromada Games">
              <img
                src={`${DOMAIN}/assets/${links[0]?.logo}`}
                alt="Gromada Games"
                onClick={() => scroll()}
              />
            </Link>
            <button
              className="button-togle navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-expanded="false"
              aria-controls="navbarCollapse"
              aria-label="Toggle navigation"
              onClick={() => setToggle((prev) => !prev)}
              type="button"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          {/* desktop menu links */}
          <div id="navbar-hide" className="collapse-hide navbar-collapse-hide">
            <ul className="nav navbar-nav navbar-right collapse">
              {links &&
                links.slice(0, 3).map((link) => (
                  <li key={link.id}>
                    <NavLink
                      onClick={() => link.url === "/contact" && refreshPage()}
                      exact="true"
                      activeclassname={"active"}
                      to={link.url}
                    >
                      {link.label}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>

          {/* mobile menu links */}
          {toggle && (
            <div id="navbar" className="collapse navbar-collapse">
              <ul className="nav navbar-nav navbar-right collapse  hide">
                {links &&
                  links.slice(0, 3).map((link) => (
                    <li
                      onClick={() => {
                        closeMenu();
                      }}
                      key={link.id}
                    >
                      <NavLink
                        onClick={() => {
                          link.url === "/contact" && refreshPage();
                        }}
                        exact="true"
                        activeclassname={"active"}
                        className="nav-link js-scroll-trigger"
                        to={link.url}
                      >
                        {link.label}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Nav;
